import { reactive } from 'vue'

export interface TCPA {
        content?: string,
        description?: string,
        key?: number,
}

export const tcpa: TCPA = reactive(window.__TCPA)

export function updateTCPA(newTCPA: TCPA) {
    Object.assign(tcpa, newTCPA)
}
