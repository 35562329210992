import { createApp } from 'vue'
import App from '../client/App.vue'
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router'
import { routes } from '../client/Routes'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { apiPlugin, StoryblokVue } from '@storyblok/vue'
import Block1 from '../client/components/landing/story_blok/blocks/Block1.vue'
import Block2 from '../client/components/landing/story_blok/blocks/Block2.vue'
import Block3 from '../client/components/landing/story_blok/blocks/Block3.vue'
import Block4 from '../client/components/landing/story_blok/blocks/Block4.vue'
import Block5 from '../client/components/landing/story_blok/blocks/Block5.vue'
import Block6 from '../client/components/landing/story_blok/blocks/Block6.vue'
import Block7 from '../client/components/landing/story_blok/blocks/Block7.vue'
import Block8 from '../client/components/landing/story_blok/blocks/Block8.vue'
import BrandedBlock1 from '../client/components/landing/story_blok/blocks/BrandedBlock1.vue'
import BrandedBlock2 from '../client/components/landing/story_blok/blocks/BrandedBlock2.vue'
import DynamicTitleBlock from '../client/components/landing/story_blok/blocks/DynamicTitleBlock.vue'
import DynamicImageBlock from '../client/components/landing/story_blok/blocks/DynamicImageBlock.vue'
import DynamicParagraphBlock from '../client/components/landing/story_blok/blocks/DynamicParagraphBlock.vue'
import DynamicButtonBlock from '../client/components/landing/story_blok/blocks/DynamicButtonBlock.vue'
import DynamicButtonGroupBlock from '../client/components/landing/story_blok/blocks/DynamicButtonGroupBlock.vue'
import DynamicHighlightBlock from '../client/components/landing/story_blok/blocks/DynamicHighlightBlock.vue'
import DynamicClickToCallBlock from '../client/components/landing/story_blok/blocks/DynamicClickToCallBlock.vue'
import DynamicRingbaTagOverrideBlock from '../client/components/landing/story_blok/blocks/DynamicRingbaTagOverrideBlock.vue'
import DynamicInlineWebFormBlock from '../client/components/landing/story_blok/blocks/DynamicInlineWebFormBlock.vue'
import DynamicVimeoBlock from '../client/components/landing/story_blok/blocks/DynamicVimeoBlock.vue'
import StandardBlock from '../client/components/landing/story_blok/blocks/StandardBlock.vue'
import DynamicTimerBlock from '../client/components/landing/story_blok/blocks/DynamicTimerBlock.vue'
import VideoBlock from '../client/components/landing/story_blok/blocks/VideoBlock.vue'
import Page from '../client/components/landing/story_blok/components/Page.vue'
import DynamicPage from '../client/components/landing/story_blok/components/DynamicPage.vue'
import BrandedPage from '../client/components/landing/story_blok/components/BrandedPage.vue'
import DynamicThankYouPage from '../client/components/thankyou/story_blok/DynamicThankYouPage.vue'
import ChecklistItem from '../client/components/landing/story_blok/components/ChecklistItem.vue'
import FaqItem from '../client/components/landing/story_blok/components/FaqItem.vue'
import FooterLink from '../client/components/landing/story_blok/components/FooterLink.vue'
import Card1 from '../client/components/landing/story_blok/components/cards/Card1.vue'
import Card2 from '../client/components/landing/story_blok/components/cards/Card2.vue'
import BigMediaCard from '../client/components/landing/story_blok/components/cards/BigMediaCard.vue'
import SlimMediaCard from '../client/components/landing/story_blok/components/cards/SlimMediaCard.vue'
import RingbaButton from '../client/components/landing/story_blok/components/buttons/RingbaButton.vue'
import BrandedRingbaButton from '../client/components/landing/story_blok/components/buttons/BrandedRingbaButton.vue'
import StartWebFormButton from '../client/components/landing/story_blok/components/buttons/StartWebFormButton.vue'
import Card3 from '../client/components/landing/story_blok/components/cards/Card3.vue'
import { RouteKey } from '../client/lib/enums/RouteKey'

const router: Router = createRouter({
    history: createWebHistory(window.__STATE.domain.content.path),
    routes,
})

router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): void => {

    const routeKeys: RouteKey[] = [
        RouteKey.WebForm,
        RouteKey.Chat,
        RouteKey.InlineWebForm,
        RouteKey.Loading,
    ]

    if (
        (from.name === RouteKey.ThankYou || from.name === RouteKey.Loading) &&
        routeKeys.includes(to.name as RouteKey)
    ) {
        window.location.reload()
    } else {
        next()
    }

})

export default router

const app = createApp(App)

/**
 * Blocks
 */
app.component('Block1', Block1)
app.component('Block2', Block2)
app.component('Block3', Block3)
app.component('Block4', Block4)
app.component('Block5', Block5)
app.component('Block6', Block6)
app.component('Block7', Block7)
app.component('Block8', Block8)
app.component('BrandedBlock1', BrandedBlock1)
app.component('BrandedBlock2', BrandedBlock2)
app.component('DynamicTitleBlock', DynamicTitleBlock)
app.component('DynamicImageBlock', DynamicImageBlock)
app.component('DynamicParagraphBlock', DynamicParagraphBlock)
app.component('DynamicButtonBlock', DynamicButtonBlock)
app.component('DynamicButtonGroupBlock', DynamicButtonGroupBlock)
app.component('DynamicHighlightBlock', DynamicHighlightBlock)
app.component('DynamicClickToCallBlock', DynamicClickToCallBlock)
app.component('DynamicRingbaTagOverrideBlock', DynamicRingbaTagOverrideBlock)
app.component('DynamicInlineWebFormBlock', DynamicInlineWebFormBlock)
app.component('DynamicVimeoBlock', DynamicVimeoBlock)
app.component('StandardBlock', StandardBlock)
app.component('DynamicTimerBlock', DynamicTimerBlock)
app.component('VideoBlock', VideoBlock)

/**
 * Components
 */
app.component('Page', Page)
app.component('DynamicPage', DynamicPage)
app.component('DynamicThankYouPage', DynamicThankYouPage)
app.component('BrandedPage', BrandedPage)
app.component('Card1', Card1)
app.component('Card2', Card2)
app.component('Card3', Card3)
app.component('FaqItem', FaqItem)
app.component('BigMediaCard', BigMediaCard)
app.component('SlimMediaCard', SlimMediaCard)
app.component('ChecklistItem', ChecklistItem)
app.component('FooterLink', FooterLink)
app.component('RingbaButton', RingbaButton)
app.component('BrandedRingbaButton', BrandedRingbaButton)
app.component('StartWebFormButton', StartWebFormButton)

app.use(StoryblokVue, {
    accessToken: import.meta.env.VITE_STORY_BLOK_ACCESS_TOKEN,
    use: [ apiPlugin ],
})

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [ window.location.hostname, /^\// ],
        }),
    ],
    normalizeDepth: 5,
})

Sentry.setTag('type', 'frontend')
Sentry.setUser({ id: window.__STATE.visitorId })
Sentry.setContext('webForm', { id: window.__STATE.webForm.key })
Sentry.setContext('domain', { domainId: window.__STATE.domain.id })
Sentry.setContext('landingTemplate', { id: window.__STATE.webForm.landingPage?.key })
Sentry.attachErrorHandler(app, { logErrors: true })

app.use(router)
app.mount('#app')
