<template>

    <div class="space-y-4">

        <Component :is="resolve(components[ component.type ], component)"
                   v-for="(component, index) of currentStep.components"
                   :key="index"
                   :ref="element => references[ component.data.name ] = element"
                   :component="component"
                   @hide-button="showButton = false"
                   @progress="progress"/>

        <div v-if="showButton" class="flex items-stretch justify-items-stretch">

            <PushButtonSpinner class="w-full justify-center"
                               :state="buttonState"
                               :size="buttonStyles.size"
                               :theme="buttonStyles.theme"
                               :font-size="buttonStyles.fontSize"
                               @click="progress">
                {{ buttonText }}
            </PushButtonSpinner>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref } from 'vue'
    import { StepComponent } from '../../types/Components'
    import { useRoute, useRouter } from 'vue-router'
    import { isDebugMode, requestImmediateAssistance, state } from '../../types/State'
    import StepTextInput from './StepTextInput.vue'
    import StepSpouseChildren from './StepSpouseChildren.vue'
    import StepTickBox from './StepTickBox.vue'
    import StepButtonGroup from './StepButtonGroup.vue'
    import StepSmartyAddress from './StepSmartyAddress.vue'
    import StepDateOfBirthText from './StepDateOfBirthText.vue'
    import StepDateOfBirth from './StepDateOfBirth.vue'
    import StepPhoneNumber from './StepPhoneNumber.vue'
    import StepDynamicTimes from './StepDynamicTimes.vue'
    import StepZipcode from './StepZipcode.vue'
    import StepAppointmentConfirmation from './StepAppointmentConfirmation.vue'
    import PushButtonSpinner from '../buttons/PushButtonSpinner.vue'
    import StepHiddenInput from './StepHiddenInput.vue'
    import StepCarYearMakeModel from './StepCarYearMakeModel.vue'
    import { InputComponent } from '../../lib/enums/components/InputComponent'
    import { PushButtonState } from '../../lib/enums/PushButton'
    import { RouteKey } from '../../lib/enums/RouteKey'
    import { replaceTokens } from '../../lib/Helpers'
    import { onKeyPressed } from '@vueuse/core'
    import { Actions, useFormPlanner } from '../../lib/compositions/useFormPlanner'
    import { useStepHistoryStore } from '../../lib/compositions/useStepHistoryStore'
    import { AudioManager } from '../../../js/AudioManager'
    import { tcpa } from '../../types/TCPA'

    type Components = ((value: StepComponent) => Components | void)
        | typeof StepZipcode
        | typeof StepButtonGroup
        | typeof StepHiddenInput
        | typeof StepTextInput
        | typeof StepSpouseChildren
        | typeof StepTickBox
        | typeof StepSmartyAddress
        | typeof StepSmartyAddress
        | typeof StepPhoneNumber
        | typeof StepDynamicTimes
        | typeof StepDateOfBirth
        | typeof StepDateOfBirthText
        | typeof StepCarYearMakeModel
        | typeof StepAppointmentConfirmation

    const components: Record<InputComponent, Components> = {
        [ InputComponent.Zipcode ]: StepZipcode,
        [ InputComponent.ButtonGroup ]: StepButtonGroup,
        [ InputComponent.HiddenInput ]: StepHiddenInput,
        [ InputComponent.Text ]: StepTextInput,
        [ InputComponent.SpouseChildren ]: StepSpouseChildren,
        [ InputComponent.TickBox ]: StepTickBox,
        [ InputComponent.SmartyAddress ]: StepSmartyAddress,
        [ InputComponent.PhoneNumber ]: StepPhoneNumber,
        [ InputComponent.DynamicTimes ]: StepDynamicTimes,
        [ InputComponent.CarYearMakeModel ]: StepCarYearMakeModel,
        [ InputComponent.DateOfBirth ]: data => {

            if (data.type === InputComponent.DateOfBirth) {
                return data.data.asTextInput ? StepDateOfBirthText : StepDateOfBirth
            }

        },
        [ InputComponent.AppointmentConfirmation ]: StepAppointmentConfirmation,
    }

    function resolve(component: ((data: StepComponent) => unknown) | unknown, data: StepComponent) {

        if (typeof component === 'function') {
            return component(data)
        }

        return component

    }

    export default defineComponent({
        name: 'StepComponents',
        components: {
            PushButtonSpinner,
            StepPhoneNumber,
            StepDateOfBirth,
            StepDateOfBirthText,
        },
        props: {
            inlineWebForm: { type: Boolean, required: false },
        },
        setup(props) {

            const { currentGroup, currentStep, answerStep } = useFormPlanner()
            const { hasAnsweredStep, storeAnsweredStepOffset } = useStepHistoryStore()
            const route = useRoute()
            const router = useRouter()
            const visitorId = state.visitorId
            const inputs = computed<StepComponent[]>(mapInputs)
            const references: Record<string, InstanceType<typeof StepZipcode>> = reactive({})
            const showButton = ref(true)
            const loading = ref(false)
            const audioManager = inject<AudioManager>('audioManager')

            onKeyPressed('Enter', progress)

            const {
                text = 'Continue',
                invalid = 'Invalid {label}',
            } = currentStep.value.content.button || {}

            const buttonText = ref(text)

            const buttonState = computed<PushButtonState>(() => {

                if (loading.value) {
                    return PushButtonState.Loading
                }

                return isValid() ? PushButtonState.Active : PushButtonState.Disabled

            })

            const buttonStyles = computed(() => ({

                theme: currentStep.value.content.button?.theme,
                size: currentStep.value.content.button?.size,
                fontSize: currentStep.value.content.button?.fontSize,

            }))

            function mapInputs(): StepComponent[] {

                return currentStep.value.components.filter(({ type }) => [
                    InputComponent.Text,
                    InputComponent.Zipcode,
                    InputComponent.HiddenInput,
                ].includes(type))

            }

            function isValid(): boolean {
                return Object.values(references).every(element => element.isValid())
            }

            const offset = computed(() => `${ currentGroup.value.id }:${ currentStep.value.id }`)

            async function progress(): Promise<void> {

                loading.value = true
                pauseAudio()
                storeAnsweredStepOffset(offset.value)

                const formData: Record<string, unknown> = {
                    offset: `${ currentGroup.value.id }:${ currentStep.value.id }`,
                    ...route.query,
                }

                const blacklistKeys = [ 'first_name', 'last_name', 'address', 'zip', 'email', 'phone_number', 'birthday' ]

                // Forget keys that came from the backend
                for (const key of blacklistKeys) {
                    delete formData[ key ]
                }

                for (const key in references) {

                    const instance = references[ key ]

                    if (instance.isValid() === false || await instance.fill(formData) === false) {

                        loading.value = false
                        buttonText.value = replaceTokens(invalid, instance.component.data)

                        if ('focus' in instance) {
                            instance.focus()
                        }

                        return

                    }

                    if (currentStep.value.content.showRequestImmediateAssistance) {

                        const key = 'request_immediate_assistance'

                        formData[ key ] = requestImmediateAssistance.value

                    }

                }

                if (currentStep.value.content.showTcpa) {

                    const seenTCPAId = tcpa?.key

                    if (seenTCPAId !== undefined) {
                        formData.seenTCPAId = seenTCPAId
                    }

                }

                const response = await answerStep(visitorId, formData)

                if (response.isErr()) {

                    if (isDebugMode) {
                        buttonText.value = response.error.message
                    }

                }

                if (response.isOk()) {

                    if (response.value.action === Actions.MoveToNextStep) {

                        await router.push({
                            name: props.inlineWebForm ? RouteKey.InlineWebForm : RouteKey.WebForm,
                            query: route.query,
                            params: {
                                ...route.params,
                                step: parseInt(route.params.step as string || '1') + 1,
                            },
                        })

                    }

                    if (response.value.action === Actions.RedirectToThankYouPage) {

                        const routeKey = state.webForm.content.bypassLoadingPage
                            ? RouteKey.ThankYou
                            : RouteKey.Loading

                        pauseAudio()
                        primeCompletionAudio()

                        await router.push({
                            name: routeKey,
                            params: route.params,
                            query: route.query,
                        })

                    }

                }

            }

            onMounted(() => {
                playAudio()
            })

            onUnmounted(() => {

                pauseAudio()
                audioManager.destroyEventListeners()

            })

            /**
             * To facilitate the async playing of completion audio we need to prime the audio
             * element with a silent audio file. This will allow the audio to play immediately without user interaction
             * when they hit the thank-you page...
             */
            function primeCompletionAudio() {
                audioManager.primeCompletionAudio(window.__SILENCE_AUDIO, true)
            }

            function playAudio() {

                if (hasAnsweredStep(offset.value)) {
                    return
                }

                audioManager.autoplayStepAudio(currentStep.value.audio)

            }

            function pauseAudio() {
                audioManager.pause()
            }

            return {
                InputComponent,
                resolve,
                progress,
                components,
                references,
                inputs,
                buttonState,
                buttonStyles,
                showButton,
                buttonText,
                currentStep,
            }

        },
    })

</script>
